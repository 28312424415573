import { OrganizationStatus } from '@jebel/constants';
/**
 * Determines if organization can be approved based on their status.
 *
 * @param currentStatus - Status of the organization.
 * @returns A boolean indicating whether the organization can be approved.
 */
export function canOrganizationBeApproved(currentStatus) {
    return currentStatus === OrganizationStatus.PendingApproval;
}
/**
 * Determines if organization can be rejected based on their status.
 *
 * @param currentStatus - Status of the organization.
 * @returns A boolean indicating whether the organization can be rejected.
 */
export function canOrganizationBeRejected(currentStatus) {
    return currentStatus === OrganizationStatus.PendingApproval;
}
/**
 * Determines if organization can be activated based on their status.
 *
 * @param currentStatus - Status of the organization.
 * @returns A boolean indicating whether the organization can be activated.
 */
export function canOrganizationBeActivated(currentStatus) {
    return currentStatus === OrganizationStatus.Inactive;
}
/**
 * Determines if organization can be inactivated based on their status.
 *
 * @param currentStatus - Status of the organization.
 * @returns A boolean indicating whether the organization can be inactivated.
 */
export function canOrganizationBeInactivated(currentStatus) {
    return currentStatus === OrganizationStatus.Active;
}
